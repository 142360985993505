import React from 'react'
import Hero from '../components/hero'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Hero
      title="Hov.. Siden findes ikke"
      subTitle="Vi kunne ikke finde den side du ledte efter"
    />
  </Layout>
)

export default NotFoundPage
